import React from "react";

const EggCrate = () => {
  return (
    <div class="arcade1">
      <p>
        <iframe
          title="oregonTrail"
          class="arcade1"
          loading="lazy"
          src="https://archive.org/embed/msdos_Oregon_Trail_The_1990"
          width="560"
          height="450"
          frameborder="0"
          allowfullscreen="allowfullscreen"
        ></iframe>
      </p>
    </div>
  );
};

export default EggCrate;
