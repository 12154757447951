import React from "react";

// Here, we display our main text
const LeftColumn = () => {
  //Left Column Use: Display events and news (Similar to Niles Bot when collapsed, expanded full calendar)
  //text to be displayed on the home screen

  const leftTitle = <div className="leftTitle">Current Projects</div>;

  const leftText = (
    <div className="leftText">
      Web Site Development <br /> <br />
      RPG Game Development <br /> <br />
      Checkers Development <br /> <br />
    </div>
  );
  return (
    <div className="mainText leftColumn contentShadow">
      {leftTitle}
      {leftText}
    </div>
  );
};

export default LeftColumn;
