import React from "react";
import { NavLink } from "react-router-dom";

const AboutText = () => {
  return (
    <div>
      <div className="mainText">
        <p>
          <strong>Our Mission Statement</strong>
        </p>
        <p>
          The purpose of the Coding Club is to provide club members with various
          coding opportunities outside of the classroom. Such opportunities
          include engaging with the community to increase interest in
          coding/computer science, organizing and participating in coding
          competitions, and hosting presentations in areas such as
          application/database/mobile development.
        </p>
        <br />
        <p>
          Interested in our Education Code Jam event? <br />
          <a href="https://newrivercoding.com/codejam">
            Please click here to see the full rules of the Code Jam!
          </a>
        </p>
      </div>
    </div>
  );
};

export default AboutText;
