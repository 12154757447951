import React from "react";
import { NavLink } from "react-router-dom";

const JoinText = () => {
  return (
    <div>
      <div className="mainText">
        <p>
          <strong>
            Interested in joining The New River Community College Coding Club?
          </strong>
        </p>
        <p>
          If you are a current or previous student of New River Community
          College and would like to join an active group of programming
          enthusiasts, then please fill out the below form with your VCCS email
          address. If you are an alum please use your current email address and
          let us know in the "Major" section that you are previous student and
          what years you attended please. After our officers see the request we
          will reach out with an email that will feature our discord server link
          as well as add you to our mailing list. We look forward to
          collaborating with you!
        </p>
        <br />
        <a
          href="https://forms.gle/1jSbdK62fQrJR2G77"
          target="_blank"
          rel="noreferrer"
        >
          NRCC Coding Club Membership Form
        </a>
      </div>
    </div>
  );
};

export default JoinText;
