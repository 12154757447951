import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NavbarLink = ({ page }) => {
  const title = page;

  return (
    <Link to={`/${page}`} className="button">
      {" "}
      {title}{" "}
    </Link>
  );
};
// let firstLoad = true;

const Navbar = () => {
  let [open, setNavOpen] = useState(false);
  //determines whether navbar begins collapsed or expanded (not populating on click :(  )) nuts I think it's because every time it changes
  //state it re-loads (calling this function again which hides it) yeehaw!.........boo... n'yeehaw :( yeehawn't LMAO
  //well, I have to run to class.
  //Later Neo, keep following that white rabbit (nice)
  // const mobileCheck = () =>{
  // 	if(firstLoad){
  // 		let x = (window.innerWidth < 481 ) ? !open : open;
  // 		setNavOpen(x);
  // 	}
  // 	firstLoad = false;
  // };
  //onLoad={mobileCheck()}  <-- was added to burgerButton

  return (
    <div>
      <button
        className="burgerButton"
        onClick={() => setNavOpen(!open)}
      >{`</>`}</button>
      <div className="navbarContainer">
        <div className={open ? "showNavbar" : "hideNavbar"}>
          <NavbarLink page="About" />
          <NavbarLink page="Members" />
          <NavbarLink page="CodeJam" />
          <NavbarLink page="Events" />
          <NavbarLink page="Join" />

          <a href="https://github.com/newrivercodingclub" className="button">
            <FontAwesomeIcon icon={["fab", "github"]} color="white" size="1x" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
