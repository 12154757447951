import React from "react";
import { NavLink } from "react-router-dom";

const EventsText = () => {
  return (
    <div>
      <div>
        <p>
          <h2 className="eventTitle">Education Code Jam! Fall 2022</h2>
          {/* Title Template<h2 className="eventTitle">Michael Hammond</h2> */}
        </p>
        <p className="mainText">
          Please join us after Thanksgiving Break 2022 on December 1st as we
          showcase projects built with education in mind. Any New River
          Community College student is welcome to submit a project and be
          eligible for victory in this school year's inaugural code jam! If
          interested in glory, bragging rights, and prizes just in case you are
          not a glory seeker nor a braggart, follow the instructions in the link
          below to become familiar with our rules and see our entry form.
          {/*Text Template: Join use on April 19th at 11 am as we welcome presenter Michael
          Hammond! An NRCC alum and now employee in Web Application Developement
          with a Masters in Data and Information Managment(DAIM) from Radford,
          Michael spends his free time when not gaming (old school and new) co-developing free and open-source
          software(FOSS) for the public with his cat, Leia.*/}
        </p>
        <p className="eventText">
          <a href="https://newrivercoding.com/codejam">
            Please click here to see the full rules of the Code Jam!
          </a>
          {/*Professional Link template <a href="https://www.nr.edu/">New River Community College</a> */}
        </p>
        <p className="eventText">
          {/*Personal Link Template <a href="https://www.linkedin.com/in/michael-hammond-b4152715a/">
            Michael Hammond | LinkedIn
          </a> */}
        </p>
        <p className="eventText">
          {/*Personal Link Template <a href="https://github.com/AutoUpdatingBSoD">
            Michael Hammond | GitHub
          </a> */}
        </p>
      </div>
      <div></div>
    </div>
  );
};
export default EventsText;
