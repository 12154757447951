import React from "react";

//Right column -- shows user's stats (codewars, coding comps, etc) when collapsed, and expands to open links when clicked

const RightColumn = () => {
  //text to be displayed on the home screen
  const rightTitle = <div className="bioName">Club Calendar</div>;

  const rightText = (
    <div>
      <iframe
        title="calendar"
        src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FNew_York&mode=AGENDA&showNav=1&showPrint=0&showTabs=0&showCalendars=0&showTz=0&showTitle=0&title=Coding%20Club%20Agenda&src=bnJjYy5jb2RpbmdjbHViQGdtYWlsLmNvbQ&color=%23039BE5"
        style={{
          borderWidth: 0,
          width: 250,
          height: 600,
          frameborder: 0,
          scrolling: "no",
        }}
      ></iframe>
    </div>
  );
  return (
    <div className="mainText rightColumn contentShadow">
      {rightTitle}
      {rightText}
    </div>
  );
};

export default RightColumn;
