import React from "react";

const MemberText = () => {
  return (
    //somehow this needs to pass a prop to the title component

    //pseudocode :
    //Title.currentPage = "About";

    <div className="mainText">
      <p>
        <h2>Officers</h2>
      </p>
      <details className="bioText">
        <p>
          <a href="https://github.com/dathegreat?tab=repositories">
            GitHub Link
          </a>
        </p>
        <summary className="bioName">D.A. Marcyes (President)</summary>
      </details>
      <details className="bioText">
        <p></p>
        <p>
          <a href="https://github.com/SBunnDev?tab=repositories">GitHub Link</a>
        </p>
        <summary className="bioName">Steven Bunn (Vice President)</summary>
      </details>

      <details className="bioText">
        <p>
          <a href="https://github.com/SBunnDev?tab=repositories">GitHub Link</a>
        </p>
        <summary className="bioName">Steven Bunn (Secretary Pro Temp)</summary>
      </details>
      <details className="bioText">
        <p></p>
        <p>
          <a href="https://github.com/Ciwi27">GitHub Link</a>
        </p>
        <summary className="bioName">Case Grube (Treasurer)</summary>
      </details>
    </div>
  );
};

export default MemberText;
