import React from "react";

const ResourcesText = () => {
  return (
    //somehow this needs to pass a prop to the title component

    //pseudocode :
    //Title.currentPage = "About";

    <div className="mainText">
      <h3>Help Documents and Language Cheatsheets</h3>
      <details>
        <summary className="leftTitle">Learning Resources</summary>
        <p className="bioText">
          Sources favorited by Coding Club members will be linked here in the
          near future. Please check back as this site is constantly under
          development.
        </p>
      </details>
      <details>
        <summary className="leftTitle">Cheatsheets</summary>
        <p className="bioText">
          Soon to come, Python/Java/Javascript quick references.
        </p>
      </details>
    </div>
  );
};

export default ResourcesText;
