import React from "react";

//import styled components library for dark/light mode
import { useState, useEffect } from "react";

// We use Route in order to define the different routes of our application
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

// We import all the components we need in our app
import Navbar from "./components/Navbar";
import Title from "./components/Title";
import RightColumn from "./components/RightColumn";
import MiddleColumn from "./components/MiddleColumn";
import LeftColumn from "./components/LeftColumn";
import About from "./components/pages/About.js";
import EggCrate from "./components/pages/Eggcrate.js";
import Events from "./components/pages/Events.js";
import Members from "./components/pages/Members.js";
import Resources from "./components/pages/Resources.js";
import CodeJam from "./components/pages/Codejam.js";
import Join from "./components/pages/Join";

//import Header from "./components/header";

//import FontAwesome Globally
//Tutorial from https://fontawesome.com/v5.15/how-to-use/on-the-web/using-with/react
//import ReactDOM from 'react-dom'
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
//import google analytics through react-ga4
import ReactGA from "react-ga4";
import { getAnalytics } from "firebase/analytics";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration (need to repack apiKey in future revision as a server handoff or some such)
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCW8oQ9aTNLJvurrjqHa0TDn3_q1rNSlek",
  authDomain: "club-website-mern.firebaseapp.com",
  projectId: "club-website-mern",
  storageBucket: "club-website-mern.appspot.com",
  messagingSenderId: "1094589277712",
  appId: "1:1094589277712:web:4ba3f9f65ab101554e0c95",
  measurementId: "G-PTRD49RN2P",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

ReactGA.initialize("G-B0N2P9C0KP");
ReactGA.send({ hitType: "pageview", page: "/App.js" });

library.add(fab);

const App = () => {
  return (
    <div>
      <Router>
        <Navbar />
        <Title />
        <div className="column">
          <LeftColumn />
          <div className="middleColumn">
            <Route exact path="/" component={About}></Route>
            <Route exact path="/members" component={Members}></Route>
            <Route exact path="/resources" component={Resources}></Route>
            <Route exact path="/about" component={About}></Route>
            <Route exact path="/eggcrate" component={EggCrate}></Route>
            <Route exact path="/events" component={Events}></Route>
            <Route exact path="/codejam" component={CodeJam}></Route>
            <Route exact path="/join" component={Join}></Route>
          </div>
          <RightColumn />
        </div>
      </Router>
    </div>
  );
};

export default App;
