import React from "react";

const CodeJamText = () => {
  return (
    <div className="mainText">
      <h2>Fall 2022 Code Jam</h2>
      <p>
        <strong>Theme:</strong> Education <br />
        <p>
          <i>
            We live in an era of autonomous vehicles and petabyte-scale
            processing. Why then must educators call roll and read our terrible
            handwriting like it's 1989? Why must students watch lectures with no
            captions and visualize complex ideas with pen and paper? This year's
            theme, Education, focuses on bringing computer science to the
            classroom. Your task is to use code to improve the lives of
            educators, students, and everyone in-between. Subtopics to explore
            include accessibility, efficiency, security, and interactivity.
            Submissions are not limited in scope, and can range from a simple
            command line interface to the next search engine.
          </i>
        </p>
        <strong>Timeline:</strong> October 27, 2022 – December 1, 2022
        <br />
        <strong>Project Submission Deadlines:</strong> December 1, 2022, 10:59
        am.
        <br />
      </p>
      <p>
        <h4>Prizes: </h4>
        <ul>
          <li>
            1st Place - TBD Gift Card,{" "}
            <a href="https://www.google.com/url?q=https://www.humblebundle.com/books/programming-mega-bundle-packt-books">
              PAKT Education Bundle
            </a>
          </li>
          <li>2nd Place - TBD Gift Card</li>
          <li>3rd Place - TBD Gift Card</li>
        </ul>
      </p>
      <p>
        <h4>What is a Code Jam?</h4>
        The overall purpose is to have fun working on a program in any
        language/technologies you prefer. Ideally you will have something to
        place in your portfolio.
        <br />
        You can build a website, web application, mobile app, command line
        program, database application, spreadsheet, hardware hack, etc. The
        sky's the limit! You may follow tutorials online if you prefer as well.{" "}
        <br />
        Again - We welcome participation from all skill levels. Have fun, learn
        something new, network with peers.
        <br />
      </p>
      <p>
        <h4>Participant Eligibility</h4>
        Participation is open to any student enrolled and in good standing with
        New River Community College. Participants are required to work
        individually.
      </p>
      <p>
        <h4>Participant Registration/Fees</h4>
        The Code Jam is free to every participant. We do require a registration
        so we can gauge the number of entries for planning purposes. You must be
        registered for the Jam through our{" "}
        <a
          href="https://forms.gle/oYk1ZXi3zfi8K8s8A"
          target="_blank"
          rel="noreferrer"
        >
          registration form
        </a>{" "}
        in order to be considered for prize awards. <br />
      </p>
      <p>
        <h4>Judges: </h4>
        TBD (Will solicit judging from NRCC employees, alumni, and local
        industry professionals.) <br />
      </p>
      <p>
        <h4>Project Judging </h4>
        Submissions from registered participants will be judged on these three
        criteria. Judges will use the following criteria weighted equally.
        Judges may base their scoring subjectively based on their understanding
        of the project submissions and thoughts on how each project submission
        meets the criteria. <br />
      </p>
      <p>
        <h4>Project Judging Criteria</h4>
        <ul>
          <li>
            Creativity: Was the project something previously created/out of the
            box? Did the project solely follow online tutorials? Was the project
            a hybrid of out of the box approach, online tutorials, and a new
            unique creative spin?{" "}
          </li>
          <li>
            Wow Factor: Did the project make the judges go “Wow!”? How
            impressive was the project to the judges? Is it something new that
            has never been seen? Is it such a simple elegant solution to a
            problem that it is genius and makes people ask “Why didn’t I think
            of that?” – How awesome is the project?{" "}
          </li>
          <li>
            Completion: Was the project complete? Were there programming or
            hardware errors during judging? Did the project crash and burn?
          </li>
        </ul>
      </p>
      <p>
        <h4>Project Submission Rules</h4>
        <ul>
          <li>No submission may be malicious or harmful.</li>
          <li>
            There is to be no bad language, shaming, blaming, bullying or other
            inappropriate behaviors. You may not promote illegal behavior or
            discriminate against or support invidious prejudice toward others
            along ethnic, racial, religious, or sexual grounds.
          </li>
          <li>
            Whatever you submit must be original content (you may use tutorials,
            guides, and code snippets, but not submit someone else’s work).
          </li>
          <li>
            Do not infringe on copyright (music, photos, images, etc.) or
            trademark (logos, brands, company names) or invade anyone's right to
            privacy.
          </li>
          <li>
            New River Coding Club reserves the right to determine something as
            inappropriate and therefore disqualified.
          </li>
        </ul>
      </p>
      <a
        href="https://forms.gle/oYk1ZXi3zfi8K8s8A"
        target="_blank"
        rel="noreferrer"
      >
        Official Entry Form
      </a>
    </div>
  );
};

export default CodeJamText;
